import { Controller } from "@hotwired/stimulus"
import EditorMount from "../components/editor/EditorMount"

export default class extends Controller {
  static get values() {
    return {
      placeholder: String,
    }
  }

  static get targets() {
    return ["form", "editor"]
  }

  _editor = null

  connect() {
    if(!this._editor) {
      this._editor = new EditorMount()
      this._editor.mount(this.formTarget, this.editorTarget, this.placeholderValue)
    }
  }

  disconnect() {
    if (this._editor) {
      this._editor.unmount()
    }
  }
}

