import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ticket"
export default class extends Controller {
  static get targets() { return ["panel", "trigger"] }

  get panel() { return this.panelTarget }
  _panelHeight = 0

  disconnect() {
    this.panel.classList.remove("ticket-mask", "active")
  }

  setupUX() {
    window.requestAnimationFrame(() => {
      if (this.panel.scrollHeight > 100) {
        this._panelHeight = this.panel.scrollHeight
        this.hide()
        this.showTrigger()
      } else {
        this.hideTrigger()
      }
    })
  }

  connect() {
    this.resize()

    this.panel.querySelectorAll("img").forEach(img => {
      img.addEventListener("load", this.resize.bind(this))
    })
  }

  resize(e) {
    this.setupUX()

    e?.target?.removeEventListener("load", this.resize.bind(this))
    this._panelHeight = this.panel.scrollHeight
  }

  hide() {
    this.panel.classList.add("ticket-mask", "active")
  }

  show() {
    this.hideTrigger()
    this.panel.classList.remove("active")
    this.panel.style.height = `${this._panelHeight}px`
  }

  hideTrigger() {
    this.triggerTarget.classList.add("hidden")
  }

  showTrigger() {
    this.triggerTarget.classList.remove("hidden")
  }
}
