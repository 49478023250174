import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["payment", "billingAddress", "promoCode", "confirm"]
  static values = {
    publicKey: String,
    clientSecret: String,
  }

  async connect() {
    this.stripe = Stripe(this.stripeKey, {betas: ['custom_checkout_beta_1']})

    await this.stripe.initCustomCheckout({
      clientSecret: this.clientSecretValue,
    }).then((checkout) => {
      this.checkout = checkout
      this.checkout.on("change", this.change.bind(this))

      this.billingElement = this.checkout.createElement("address", {mode: "billing"})
      this.billingElement.mount(this.billingAddressTarget)

      this.paymentElement = this.checkout.createElement("payment")
      this.paymentElement.mount(this.paymentTarget)
    })
  }

  disconnect() {
    this.checkout.destroy()
  }

  change(session) {
    console.log(this.checkout, session)

    this.confirmTarget.disabled = !session.canConfirm
  }

  applyPromo(event) {
    console.log(event.currentTarget.value)
  }

  get stripeKey() {
    return this.publicKeyValue || document.querySelector('meta[name="stripe-key"]').getAttribute("content")
  }
}
