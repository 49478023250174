import { BridgeComponent } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"
import { post, destroy } from "@rails/request.js"

// Connects to data-controller="bridge--ticket-overlow-menu
export default class extends BridgeComponent {
  static component = "ticketOverflowMenu"

  connect() {
    super.connect()

    this.element.setAttribute("data-turbo-frame", "_top")

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  get open() {
    return /true/.test(this.bridgeElement.bridgeAttribute("open"))
  }

  get ticketId() {
    return this.bridgeElement.bridgeAttribute("id")
  }

  get projectId() {
    return this.bridgeElement.bridgeAttribute("project")
  }

  get subscribed() {
    return /true/.test(this.bridgeElement.bridgeAttribute("subscribed"))
  }

  async complete() {
    await post(`/api/v1/tickets/${this.ticketId}/complete`)
  }

  async cancel() {
    await post(`/api/v1/tickets/${this.ticketId}/cancel`)
  }

  async restore() {
    await post(`/api/v1/tickets/${this.ticketId}/restore`)
  }

  async subscribe() {
    await post(`/api/v1/tickets/${this.ticketId}/subscription`)
  }

  async unsubscribe() {
    await destroy(`/api/v1/tickets/${this.ticketId}/subscription`)
  }

  registerBridge() {
    this.send("connect", { open: this.open, subscribed: this.subscribed }, async ({ data }) => {
      const frame = document.querySelector("#ticket")

      switch (data.action) {
        case "unsubscribe":
          await this.unsubscribe()
          frame.src = location.href
          break;
        case "restore":
          await this.restore()
          frame.src = location.href
          break;
        case "subscribe":
          await this.subscribe()
          frame.src = location.href
          break;
        case "edit":
          this.bridgeElement.click()
          break;
        case "complete":
          await this.complete()
          frame.src = location.href
          break;
        case "cancel":
          await this.cancel()
          frame.src = location.href
          break;
        case "new":
          Turbo.visit(`/tickets/new?project_id=${this.projectId}`)
          break;

      }
    })
  }
}
