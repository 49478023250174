import { computePosition, autoUpdate, hide, shift } from "@floating-ui/dom";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static get targets() {
    return ["trigger", "menu"];
  }

  get menu() {
    return this.menuTarget;
  }
  get trigger() {
    if (!this.hasTriggerTarget) {
      return null;
    }

    return this.triggerTarget;
  }

  _cleanup = null;

  connect() {
    this.menu.classList.add("hidden");
    this.init();
  }

  disconnect() {
    if (this._cleanup) {
      this._cleanup();
    }

    this.element.setAttribute("data-active", false);
    this.menu.classList.add("hidden");
  }

  init() {
    if (this.trigger === null) {
      return;
    }

    computePosition(this.trigger, this.menu, {
      strategy: "fixed",
      placement: "bottom-end",
      middleware: [
        hide((state) => ({
          //padding: { right: state.rects.reference.width, left: state.rects.reference.width - 4 }
        })),
        shift(),
      ],
    }).then(({ x, y, middlewareData }) => {
      Object.assign(this.menu.style, {
        left: `${x}px`,
        top: `${y}px`,
        visibility: middlewareData.hide.referenceHidden ? "hidden" : "visible",
      });
    });
  }

  hide(event) {
    if (
      event.target.nodeType &&
      this.element.contains(event.target) === false &&
      this.menu.classList.contains("hidden") === false
    ) {
      this.menu.classList.add("hidden");
      this.element.setAttribute("data-active", false);

      if (this._cleanup) {
        this._cleanup();
      }
    }
  }

  show() {
    this.element.setAttribute("data-active", true);
    this.menu.classList.remove("hidden");

    if (this.trigger === null) {
      return;
    }

    this._cleanup = autoUpdate(this.trigger, this.menu, this.init.bind(this));
  }

  captureForm(e) {
    e.preventDefault();

    e.currentTarget.form.requestSubmit();

    return false;
  }

  toggle(e) {
    if (this.element.dataset.stop === "true") {
      e.preventDefault();
      e.stopPropagation();
    }

    this.menu.classList.toggle("hidden");

    if (!this.menu.classList.contains("hidden")) {
      this.element.setAttribute("data-active", true);

      if (this.trigger) {
        this._cleanup = autoUpdate(
          this.trigger,
          this.menu,
          this.init.bind(this),
        );
      }
    } else {
      this.element.setAttribute("data-active", false);

      if (this._cleanup) {
        this._cleanup();
      }
    }
  }
}
