import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="picker"
export default class extends Controller {
  static get outlets() { return ["picker"] }
  static get targets() { return ["item", "input", "group"] }

  connect() {
    this.filter();
  }

  get terms() {
    return this.inputTarget.value
  }

  submit(e) {
    if(e.key === "Enter") {
      const item = this.itemTargets.find(item => !item.classList.contains("hidden"))

      if(item) {
        this.select(item)
        this.visitItem(item)
      }
    }
  }

  visitItem(item) {
    if (document.activeElement) {
      document.activeElement.blur()
    }

    const { href: url } = item

    setTimeout(() => {
      Turbo.visit(url)
    }, 10)
  }

  filter() {
    const terms = this.terms.toLowerCase()

    this.itemTargets.forEach(item => {
      const itemTerms = item.dataset.terms

      if (itemTerms.includes(terms)) {
        item.classList.remove("hidden")
      } else {
        item.classList.add("hidden")
      }
    })

    this.toggleGroups()
  }

  toggleGroups() {
    this.groupTargets.forEach(group => {
      const groupItems = Array.from(group.querySelectorAll("[data-picker-target=item]"))
      const isEmpty = groupItems.every(item => item.classList.contains("hidden"))

      if (isEmpty) {
        group.classList.add("hidden")
      } else {
        group.classList.remove("hidden")
      }
    })
  }

  select(item) {
    this.itemTargets.forEach(item => this.deselect(item))

    const toggle = item.querySelector("i")
    toggle.classList.remove("fa-circle", "text-gray-300", "dark:text-gray-800", "far")
    toggle.classList.add("fa-circle-check", "text-blue-600", "fas")
  }

  deselect(item) {
    const toggle = item.querySelector("i")
    toggle.classList.remove("fa-circle-check", "text-blue-600", "fas")
    toggle.classList.add("fa-circle", "text-gray-300", "dark:text-gray-800", "far")
  }

  on(e) {
    e.preventDefault()

    this.select(e.currentTarget)
    this.visitItem(e.currentTarget)
  }
}
