import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.setAttribute("data-active", false);
  }

  invoke() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  calculate() {
    if (window.scrollY > window.innerHeight) {
      this.element.setAttribute("data-active", true);
    } else {
      this.element.setAttribute("data-active", false);
    }
  }
}
