import { BridgeComponent } from "@hotwired/strada"

// Connects to data-controller="bridge--navbar-mutation
export default class extends BridgeComponent {
  static component = "navbarMutation"

  connect() {
    super.connect()

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  registerBridge() {
    const { backgroundColor } = this.element.dataset

    this.send("connect", { backgroundColor, foregroundColor: "#ffffff" }, () => {})
  }
}
