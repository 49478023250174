import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="viewport"
export default class extends Controller {
  connect() {
    window.visualViewport.addEventListener("resize", this.onResize.bind(this))
  }

  disconnect() {
    window.visualViewport.removeEventListener("resize", this.onResize.bind(this))
  }

  onResize() {
    this.element.style.height = `${window.visualViewport.height}px`
  }
}
