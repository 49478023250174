import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-submitter"
export default class extends Controller {
  static get targets() {
    return ["form"];
  }

  handleSubmit() {
    window.requestAnimationFrame(() => {
      this.formTarget.requestSubmit();
    });
  }
}
