import { BridgeComponent, BridgeElement } from "@hotwired/strada"

// Connects to data-controller="bridge--ticket-comment"
export default class extends BridgeComponent {
  static component = "ticketComment"

  connect() {
    super.connect()

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  registerBridge() {
    this.element.setAttribute("data-turbo-frame", "_top")
    const submitButton = new BridgeElement(this.element)

    this.send("connect", {}, () => {
      submitButton.click()
    })
  }
}
