import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="drawer"
export default class extends Controller {
  _drawer

  static get values() { return { timeout:{ type: Number, default: 10 } } }

  connect() {
    this._drawer = this.element
    this.show()
  }

  disconnect() {
    this.hide()
  }

  waitForShow() {
    this.show()
  }

  hide() {
    this._drawer.hide()
    TurboNativeBridge.hideDimmedBackdrop()
  }

  show() {
    if('show' in this._drawer) {
      setTimeout(() => {
        this._drawer.show()
        TurboNativeBridge.showDimmedBackdrop()
      }, this.timeoutValue);
      return
    }

    window.requestAnimationFrame(() => this.show())
  }
}
