import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { post } from "@rails/request.js";

// Connects to data-controller="kanban"
export default class extends Controller {
  static get targets() {
    return ["lane", "card"];
  }

  static get values() {
    return { projectId: Number };
  }

  sortedLanes = [];

  visit({ currentTarget }) {
    Turbo.visit(`/tickets/${currentTarget.dataset.id}/comments`, { action: "advance" })
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      draggable: ".kb-lane",
      forceFallback: true,
      easing: "cubic-bezier(1, 0, 0, 1)",
      animation: 150,
      ghostClass: "kb-ghost",
      dragClass: "kb-drag",
      onEnd: (e) => {
        console.log({ e });
      },
    });

    this.laneTargets.forEach((lane) => {
      const sortable = Sortable.create(lane, {
        group: "kb-lane",
        forceFallback: true,
        draggable: ".kb-card",
        easing: "cubic-bezier(1, 0, 0, 1)",
        animation: 150,
        ghostClass: "kb-ghost",
        dragClass: "kb-drag",
        onEnd: async ({ item, to, newIndex: position }) => {
          const lane = to.dataset.lane;
          const ticket_id = item.dataset.id;

          await post(
            `/api/v1/projects/${this.projectIdValue}/kanban/reposition-ticket`,
            {
              contentType: "application/json",
              responseKind: "turbo-stream",
              body: JSON.stringify({ ticket_id, lane, position }),
            },
          );
        },
      });

      this.sortedLanes.push(sortable);
    });
  }

  disconnect() {
    this.sortedLanes.forEach((lane) => {
      lane.destroy();
    });
  }
}
