import { Controller } from "@hotwired/stimulus";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/tokyo-night-dark.css";

// Connects to data-controller="code-highlighter"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("code").forEach((block) => {
      const pre = document.createElement("pre");
      block.parentNode.replaceChild(pre, block);
      pre.appendChild(block);
      hljs.highlightElement(block);
    });
  }
}
