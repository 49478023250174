import { Controller } from "@hotwired/stimulus"
import React from "react";
import { createRoot } from "react-dom/client"
import Swipeable from "../components/swipeables/Swipeable"

// Connects to data-controller="swipeable"
export default class extends Controller {
  _component = null
  _classes = null
  _content = null

  static get values() { return { url: String } }

  connect() {
    let url = this.urlValue

    if (window.linkCache) {
      const { cacheKey } = this.element.dataset
      url = linkCache[cacheKey] || this.urlValue
    }

    this._content = this.element.innerHTML
    this._component = createRoot(this.element)
    this._component.render(
      <Swipeable content={this._content} url={url} />
    )
  }

  disconnect() {
    if (this._component) {
      this._component.unmount()
      this.element.innerHTML = this._content
      this.element.className = `${this._classes}`
    }
  }
}
