import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wait-for-stream"
export default class extends Controller {
  static get targets() { return ["frame"] }

  _streams = []

  connect() {
    this.frameTargets.forEach(frame => frame.disabled = true)

    this._streams = Array.from(document.querySelectorAll("turbo-cable-stream-source"))

    this._streams.forEach((stream) => {
      const observer = new MutationObserver(() => {
        observer.disconnect()

        this._streams = this._streams.filter((s) => s != stream)

        if(this._streams.length == 0) {
          this.frameTargets.forEach((frame) => { 
            frame.disabled = false
          })
        }
      })

      observer.observe(stream, { attributeFilter: ["connected"] })
    })
  }
}
