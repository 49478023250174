import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

// Connects to data-controller="tag-field"
export default class extends Controller {
  _tagify

  static get values() {
    return {
      whitelist: Array
    }
  }

  connect() {
    let additionalOptions = {}
    if (this.hasWhitelistValue && this.whitelistValue.length > 0) {
      additionalOptions = {
        whitelist: this.whitelistValue,
        dropdown: {
          enabled: 0,
          closeOnSelect: false
        }
      }
    }

    this._tagify = new Tagify(this.element, {
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(','),
      ...additionalOptions,
    })
  }

  disconnect() {
    this._tagify?.destroy()
    this._tagify = null
  }
}
