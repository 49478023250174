import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-room"
export default class extends Controller {
  static get targets() { return ["message"] }

  _loaded = false
  _handle = null

  messageTargetConnected(target) {
    if(this._loaded == false) {
      clearTimeout(this._handle)
      this.isLoaded()
      target.scrollIntoView({ behavior: "instant", inline: "end", block: "end" })
    } else {
      target.scrollIntoView({ behavior: "smooth", inline: "end", block: "end" })
    }
  }

  connect() {
    this.isLoaded()
  }

  focus() {
    window.scrollTo(0, window.visualViewport.height)
  }

  isLoaded() {
    this._handle = setTimeout(() => {
      this._loaded = true
    }, 50)
  }
}
