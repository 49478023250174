import { BridgeComponent } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"
import { post } from "@rails/request.js"

// Connects to data-controller="bridge--space-overlow-menu
export default class extends BridgeComponent {
  static component = "spaceOverflowMenu"

  connect() {
    super.connect()

    this.element.setAttribute("data-turbo-frame", "_top")

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  get open() {
    return /true/.test(this.bridgeElement.bridgeAttribute("open"))
  }

  get spaceId() {
    return this.bridgeElement.bridgeAttribute("id")
  }

  async restore() {
    await post(`/api/v1/spaces/${this.spaceId}/restore`)
  }

  async archive() {
    await post(`/api/v1/spaces/${this.spaceId}/archive`)
  }

  registerBridge() {
    this.send("connect", { open: this.open }, async ({ data }) => {
      const frame = document.querySelector("#space")

      switch (data.action) {
        case "edit":
          this.bridgeElement.click()
          break;
        case "archive":
          await this.archive()
          frame.src = location.href
          break;
        case "restore":
          await this.restore()
          frame.src = location.href
          break;
        case "new":
          Turbo.visit("/spaces/new")
          break;

      }
    })
  }
}
