import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion"
import { Turbo } from "@hotwired/turbo-rails"

const Swipeable = ({ content, url }) => {
  const [dragging, setDragging] = useState(false)

  const handleDragStart = () => setDragging(true)
  const handleDragEnd = () => {
    setTimeout(() => setDragging(false), 500)
  }

  const handleClick = () => {
    if(dragging) {
      return
    }

    Turbo.visit(url, { frame: "_top" })
  }

  return ( 
    <AnimatePresence>
      <div className="relative w-full">
        <motion.div
          className="relative z-10 bg-white"
          preventScroll={true}
          drag="x"
          dragDirectionLock={true}
          dragConstraints={{ left: 0, right: 0 }}
          dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
          dragElastic={0.5}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <div onClick={handleClick} className="w-full relative flex space-x-4 py-4" dangerouslySetInnerHTML={{ __html: content}}></div>
        </motion.div>
        <div className="absolute top-0 right-0 bg-red-600 text-white w-[70px] h-full flex items-center justify-center">Archive</div>
      </div>
    </AnimatePresence>
  )
}

export default Swipeable
