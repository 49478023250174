import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reminders"
export default class extends Controller {
  static get targets() { return ["field", "container"]}
  connect() {
    this.updateUI()
  }

  change() {
    this.updateUI()
  }

  updateUI() {
    if(this.fieldTarget.value.length > 0) {
      this.containerTarget.classList.remove("hidden")
    } else {
      this.containerTarget.classList.add("hidden")
    }
  }
}
