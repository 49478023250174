import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-clicker"
export default class extends Controller {
  static get targets() { return ["link"] }

  invoke() {
    this.linkTarget.click()
  }
}
