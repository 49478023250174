import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="frame-buffer"
export default class extends Controller {
  static get targets() { return ["back", "front"] }

  _observer = null

  backTargetConnected(target) {
    this.reset()

    if(target.hasAttribute("inline")) {
      this.clone()
      return
    }

    this._observer = new MutationObserver(() => {
      this.clone()
    })

    this._observer.observe(this.backTarget, { attributeFilter: ["complete"] })
  }

  disconnect() {
    this.reset()
  }

  clone() {
    const { id, innerHTML: buffer, style, className, data } = this.backTarget

    document.querySelector("main").scrollTo(0, 0)

    this.frontTarget.innerHTML = buffer
    this.frontTarget.id = id
    this.frontTarget.style = style
    this.frontTarget.className = className
    this.frontTarget.data = data

    this.backTarget.remove()
  }

  reset() {
    if(this._observer) {
      this._observer.disconnect()
    }
  }
}
