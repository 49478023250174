// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
const controllers = import.meta.glob("./**/*_controller.js*", { eager: true, import: "default" })
// loop over all the controllers

for (const path in controllers) {
  const controller = controllers[path]
  const name = path
                .replace(/\.\/|_controller\.js/g, "")
                .replace(/_/g, "-")
                .replace(/\//g, "--")
  application.register(name, controller)
}

import { Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)
