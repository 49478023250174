import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="task"
export default class extends Controller {
  static get values() {
    return {
      url: String,
    }
  }

  static get targets() { return ["completed", "pending"] }

  async toggle() {
    const url = `${this.urlValue}/complete`
    patch(url, { responseKind: "turbo-stream" })
  }
}
