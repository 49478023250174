import { get } from "@rails/request.js"
import React from "react";
import { createRoot } from "react-dom/client"
import Editor from "./Editor"

export default class EditorMount {
  _editor = null;
  _formTarget = null;
  _editorTarget = null;
  _placeholder = null;

  handleUpdate(html) {
    this._formTarget.value = html
  }

  async mount(formTarget, editorTarget, placeholder) {
    this._formTarget = formTarget
    this._editorTarget = editorTarget
    this._placeholder = placeholder

    const cachedTeam = await this.team()
    this.boundHandleUpdate = this.handleUpdate.bind(this)

    if (!this._editor) {
      this._editor = createRoot(this._editorTarget)
    }

    this._editor.render(
      <Editor
        team={cachedTeam}
        content={this.buffer}
        readonly={this.readonly}
        placeholder={this.placeholder}
        onUpdate={this.boundHandleUpdate} />,
    )
  }

  unmount() {
    if(this._editor) {
      this._editor.unmount()
    }
  }

  content() {
    const { formTarget: target } = this

    if (target instanceof HTMLDivElement) {
      return target.innerHTML
    }

    return target.value
  }

  async team() {
    const resp = await get("/users/mentions.json", {
      contnetType: "application/json",
      responseKind: "json",
      query: { query: '' }
    })

    const users = await resp.json
    return users
  }

  get buffer() {
    if (this.readonly) {
      return this._formTarget.innerHTML
    }

    return this._formTarget.value
  }

  get readonly() {
    return this._formTarget instanceof HTMLDivElement
  }

  get placeholder() {
    return this._placeholder
  }
}
