import { BridgeComponent, BridgeElement } from "@hotwired/strada"

// Connects to data-controller="bridge--form
export default class extends BridgeComponent {
  static component = "form"

  connect() {
    super.connect()

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  registerBridge() {
    const submitButton = new BridgeElement(this.element)
    const submitTitle = submitButton.title

    this.send("connect", { submitTitle }, () => {
      submitButton.click()
    })
  }
}
