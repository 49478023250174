import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get values() {
    return {
      url: String,
    }
  }

  handle() {
    Turbo.visit(this.urlValue)
  }
}

