import { BridgeComponent, BridgeElement } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="bridge--title-context-menu"
export default class extends BridgeComponent {
  static component = "titleContextMenu"

  connect() {
    super.connect()

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  registerBridge() {
    const context = this.bridgeElement.bridgeAttribute("context")
    const id = this.bridgeElement.bridgeAttribute("id")

    this.send("connect", { id, context }, (resp) => {
      Turbo.visit(resp.data.action)
    })
  }
}
