import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message-form"
export default class extends Controller {
  static get targets() { return ["input", "message"] }

  handleKeyPress(event) {
    if(event.code === 'Enter') {
      //this.element.requestSubmit()
    }
  }

  focused(e) {
    /* e.preventDefault()
    e.stopPropagation()

    e.target.blur()
    window.requestAnimationFrame(() => e.target.focus())
    */
  }
}
