/* eslint no-console:0 */

import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import * as LocalTime from "local-time";

import "./channels";
import "./controllers";

import.meta.glob("./src/**/*", { eager: true });

let scrollPositions = {};

function preserveScroll() {
  document.querySelectorAll("[data-preserve-scroll").forEach((element) => {
    scrollPositions[element.id] = {
      left: element.scrollLeft,
      top: element.scrollTop,
    };
  });
}

function restoreScroll(event) {
  document.querySelectorAll("[data-preserve-scroll").forEach((element) => {
    if (scrollPositions[element.id]) {
      element.scrollLeft = scrollPositions[element.id].left;
      element.scrollTop = scrollPositions[element.id].top;
    }
  });

  if (!event.detail?.newBody) return;
  // event.detail.newBody is the body element to be swapped in.
  // https://turbo.hotwired.dev/reference/events
  event.detail.newBody
    .querySelectorAll("[data-preserve-scroll")
    .forEach((element) => {
      if (scrollPositions[element.id]) {
        element.scrollLeft = scrollPositions[element.id].left;
        element.scrollTop = scrollPositions[element.id].top;
      }
    });
}

ActiveStorage.start();
LocalTime.start();

document.documentElement.addEventListener("turbo:click", preserveScroll);
document.documentElement.addEventListener("turbo:before-cache", preserveScroll);

document.documentElement.addEventListener("turbo:before-render", restoreScroll);
document.documentElement.addEventListener("turbo:render", restoreScroll);

document.documentElement.addEventListener(
  "turbo:before-frame-render",
  restoreScroll,
);
document.documentElement.addEventListener("turbo:frame-render", restoreScroll);
