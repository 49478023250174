import { BridgeComponent } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"
import { post } from "@rails/request.js"

// Connects to data-controller="bridge--project-overlow-menu
export default class extends BridgeComponent {
  static component = "projectOverflowMenu"

  connect() {
    super.connect()

    this.element.setAttribute("data-turbo-frame", "_top")

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  get open() {
    return /true/.test(this.bridgeElement.bridgeAttribute("open"))
  }

  get projectId() {
    return this.bridgeElement.bridgeAttribute("id")
  }

  get spaceId() {
    return this.bridgeElement.bridgeAttribute("spaceId")
  }

  async cancel() {
    await post(`/api/v1/projects/${this.projectId}/cancel`)
  }

  async complete() {
    await post(`/api/v1/projects/${this.projectId}/complete`)
  }

  async restore() {
    await post(`/api/v1/projects/${this.projectId}/restore`)
  }

  registerBridge() {
    this.send("connect", { open: this.open }, async ({ data }) => {
      const frame = document.querySelector("#project")

      switch (data.action) {
        case "edit":
          this.bridgeElement.click()
          break;
        case "restore":
          await this.restore()
          frame.src = location.href
          break;
        case "complete":
          await this.complete()
          frame.src = location.href
          break;
        case "cancel":
          await this.cancel()
          frame.src = location.href
          break;
        case "new":
          Turbo.visit(`/projects/new?space_id=${this.spaceId}`)
          break;

      }
    })
  }
}
