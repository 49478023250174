import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"

export default class extends Controller {
  _tagify = null

  static get values() {
    return {
      whitelist: Array,
    }
  }

  connect() {
    this._tagify = new Tagify(this.element, {
      whitelist: this.whitelistValue,
      tagTextProp: "name",
      originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value).join(','),
      dropdown: {
        closeOnSelect: false,
        enabled: 0,
        searchKeys: ['name']
      },
      templates: {
        tag: this.tagTemplate,
        dropdownItem: this.suggestionItemTemplate
      },
      transformTag(tagData) {
        const obj = this.settings.whitelist.find(x => x.value == tagData.value)

        tagData.name = obj.name
        tagData.avatar = obj.avatar
      }
    })
  }

  disconnect() {
    this._tagify?.destroy()
    this._tagify = null
  }

  tagTemplate(tagData) {
    return `
        <tag title="${tagData.name}"
          contenteditable='false'
          spellcheck='false'
          tabIndex="-1"
          class="tagify__tag"
          ${this.getAttributes(tagData)}
        >
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div class='inline-flex items-center space-x-2'>
              ${tagData.avatar}
              <span class='tagify__tag-text'>${tagData.name}</span>
            </div>
        </tag>
    `
  }

  suggestionItemTemplate(tagData) {
    return `
      <div
        tabindex="0"
        class='tagify__dropdown__item flex items-center space-x-2'
        role="option"
        ${this.getAttributes(tagData)}
        >
        ${tagData.avatar}
        <span>${tagData.name}</span>
      </div>
`
  }
}
