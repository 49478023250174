import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="task-list"
export default class extends Controller {
  static get targets() { return ["cta", "list"] }

  show() {
    this.ctaTarget.classList.add("hidden")
    this.listTarget.classList.remove("hidden")
  }
}
