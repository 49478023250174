import { BridgeComponent, BridgeElement } from "@hotwired/strada"

// Connects to data-controller="bridge--fab
export default class extends BridgeComponent {
  static component = "actionItem"

  connect() {
    super.connect()

    this.element.setAttribute("data-turbo-frame", "_top")

    window.requestAnimationFrame(() => {
      this.registerBridge()
    })
  }

  registerBridge() {
    const actionButton = new BridgeElement(this.element)

    this.send("connect", { title: actionButton.title }, () => {
      actionButton.click()
    })
  }
}
