import { Controller } from "@hotwired/stimulus"

if (!window.scrollCache) {
  window.scrollCache = {}
}

if (!window.linkCache) {
  window.linkCache = {}
}

// Connects to data-controller="tabs"
export default class extends Controller {
  _observer = null

  get tabs() { return Array.from(this.element.querySelectorAll(".tab")) }

  connect() {
    const point = window.scrollCache[this.element.id]
    if (point) {
      this.element.scrollLeft = point.left
      this.element.scrollTop = point.top
    }

    this._observer = new IntersectionObserver(() => {
      let { scrollLeft: left, scrollTop: top } = this.element

      if (left < 0) left = 0
      if (top < 0) top = 0

      window.scrollCache[this.element.id] = {
        top,
        left
      }
    }, {
      root: this.element,
      rootMargin: "0px",
      threshold: 1,
    })

    this.tabs.forEach(tab => {
      this._observer.observe(tab)
      tab.addEventListener("click", this.handleClick.bind(this))
    })

  }

  handleClick(e) {
    const url = e.currentTarget.href
    const cacheKey = e.currentTarget.dataset.cacheKey
    linkCache[cacheKey] = url
  }

  disconnect() {
    this._observer.disconnect()

    this.tabs.forEach(tab => {
      tab.removeEventListener("click", this.handleClick.bind(this))
    })
  }
}
