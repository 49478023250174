import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notice"
export default class extends Controller {
  static get values() {
    return {
      duration: {
        type: Number,
        default: 2000
      }
    }
  }

  _handle = null

  connect() {
    this._handle = setTimeout(this.animateOut.bind(this), this.durationValue)
  }

  animateOut() {
    if(this._handle) {
      clearTimeout(this._handle)
      this._handle = null
    }

    this.element.classList.add("slideOut")
  }

  disconnect() {
    this.hide()
  }

  hide() {
    if(this.element) {
      this.element.remove()
    }
  }
}
