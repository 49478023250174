import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {
  _drawer
  _visible = false

  connect() {
    this._drawer = this.element
  }

  disconnect() {
    this.hide()
    this._drawer = null
  }

  markHidden() {
    this._visible = false
  }

  toggle() {
    this._visible = !this._visible

    if(this._visible) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() { this._drawer.show(); this._visible = true }
  hide() { this._drawer.hide(); this._visible = false }
}
