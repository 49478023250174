import { EditorContent, useEditor } from "@tiptap/react"
import StarterKit from '@tiptap/starter-kit'
import Mention from "@tiptap/extension-mention"
import Blockquote from "@tiptap/extension-blockquote"
import Link from "@tiptap/extension-link"
import TaskList from "@tiptap/extension-task-list"
import TaskItem from "@tiptap/extension-task-item"
import Placeholder from "@tiptap/extension-placeholder"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import css from "highlight.js/lib/languages/css"
import js from "highlight.js/lib/languages/javascript"
import ts from "highlight.js/lib/languages/typescript"
import html from "highlight.js/lib/languages/xml"
import ruby from "highlight.js/lib/languages/ruby"
import { lowlight } from "lowlight"
import suggestion from "../suggestions/Suggestions.js"
import React from "react";

lowlight.registerLanguage("css", css)
lowlight.registerLanguage("ts", ts)
lowlight.registerLanguage("js", js)
lowlight.registerLanguage("html", html)
lowlight.registerLanguage("ruby", ruby)

export default ({ content, readonly, placeholder, onUpdate, team }) => {
  const editor = useEditor({
    //const suggestion = await this.team()
    editable: !readonly,
    extensions: [
      StarterKit.configure({
        blockquote: false,
      }),
      Blockquote,
      Mention.configure({
        suggestion: suggestion(team),
        HTMLAttributes: {
          class: 'mention',
        },
        renderLabel({ options, node }) {
          const member = team.find(x => x.id == node.attrs.id)

          if (member) {
            return `${options.suggestion.char}${member.name}`
          } else {
            return `${options.suggestion.char}${node.attrs.label}`
          }
        }
      }),
      Link.configure({
        HTMLAttributes: {
          class: "editor-link",
          target: "_blank",
        }
      }),
      TaskList.configure({
        HTMLAttributes: {
          class: "not-prose"
        }
      }),
      TaskItem.configure({
        HTMLAttributes: {
          class: "task-item not-prose"
        },
        onReadOnlyChecked: (_node, _checked) => {
          return false
        },
        nested: true,
      }),
      Placeholder.configure({
        placeholder
      }),
      CodeBlockLowlight
        .extend({
          addKeyboardShortcuts() {
            return {
              Tab: () => {
                if (this.editor.isActive("codeBlock")) {
                  return this.editor.commands.insertContent("  ")
                }
              }
            }
          }
        })
        .configure({
          lowlight
        })
    ],
    // @ts-ignore
    content,
    parseOptions: {
      preserveWhitespace: "full",
    },
    onUpdate: ({ editor }) => {
      if (readonly) {
        return
      }
      let html = editor.getHTML()
      if (html === '<p></p>') html = ''

      if (onUpdate) onUpdate(html)
      // @ts-ignore
      //this.formTarget.value = html
    },
    editorProps: {
      attributes: {
        class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl focus:outline-none',
      },
    },
  })

  return <EditorContent editor={editor} />
}

