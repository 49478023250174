import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="check-all"
export default class extends Controller {
  static get targets() {
    return ["checkbox", "main", "status", "action"];
  }

  initialize() {
    this.boundFilterCallback = this.updateAvailableFilters.bind(this);
  }

  connect() {
    window.navigation.addEventListener("navigate", this.boundFilterCallback);

    this.updateAvailableFilters({ destination: { url: window.location } });
  }

  disconnect() {
    window.navigation.removeEventListener("navigate", this.boundFilterCallback);
  }

  updateAvailableFilters(e) {
    const url = new URL(e.destination.url);
    const queryParams = new URLSearchParams(url.search);

    let state = queryParams.get("state") || "open";

    switch (state) {
      case "overdue":
        state = "open";
        break;
      case "open":
        break;
      case "cancelled":
        state = "closed";
        break;
      case "completed":
        state = "closed";
        break;
    }

    this.actionTargets.forEach((action) => {
      const when = action.dataset.when

      action.classList.remove("hidden");

      if(when && when != state) {
        action.classList.add("hidden");
      }
    });
  }

  check() {
    const anyChecked = this.checkboxTargets.some(
      (checkbox) => checkbox.checked,
    );

    this.mainTarget.checked = anyChecked;
    this.updateUI();
  }

  updateUI() {
    const count = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked,
    ).length;
    const resource = count === 1 ? "ticket" : "tickets";
    this.statusTarget.textContent = `${count} ${resource} selected`;
    this.element.setAttribute("data-checked", this.mainTarget.checked);

    this.actionTargets.forEach((action) => {
      action.href = this.constructActionUrl(action.href);
    });
  }

  constructActionUrl(url) {
    // update id array query string
    const ids = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    url = new URL(url);

    const queryParams = new URLSearchParams(url.search);

    if (queryParams.has("callback")) {
      const callbackUrl = new URL(queryParams.get("callback"));

      const callbackParams = new URLSearchParams(callbackUrl.search);
      callbackParams.set("ids", ids);
      callbackUrl.search = callbackParams.toString();

      queryParams.set("callback", callbackUrl.toString());
    } else {
      queryParams.set("ids", ids);
    }

    url.search = queryParams.toString();
    return url.toString();
  }

  checkAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true;
    });
  }

  uncheckAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  toggleAll() {
    if (this.mainTarget.checked) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }

    this.updateUI();
  }
}
