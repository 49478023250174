import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="email-client"
export default class extends Controller {
  static get targets() {
    return ["email"];
  }

  set({ currentTarget }) {
    this.element.setAttribute("data-active", "");
    this.emailTargets.forEach((email) => email.removeAttribute("data-active"));
    currentTarget.setAttribute("data-active", "");
  }

  unset() {
    this.element.removeAttribute("data-active");
    this.emailTargets.forEach((email) => email.removeAttribute("data-active"));
  }
}
