import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sheet"
export default class extends Controller {
  close() {
    TurboNativeBridge.closeSheet()
  }

  open(e) {
    e.preventDefault();

    TurboNativeBridge.openSheet(this.element.href)
  }
}
