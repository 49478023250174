import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static get targets() {
    return ["input"];
  }

  focus(e) {
    e.preventDefault();
    this.inputTarget.focus();
    this.inputTarget.select();
  }
}
