import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    publicKey: String,
    clientSecret: String,
    // returnUrl: String
  }

  async connect() {
    this.stripe = Stripe(this.stripeKey)
    this.checkout = await this.stripe.initEmbeddedCheckout({
      clientSecret: this.clientSecretValue,
      // onComplete: () => {
      //   Turbo.visit(this.returnUrlValue)
      // }
    })
    this.checkout.mount(this.element)
  }

  disconnect() {
    this.checkout.destroy()
  }

  get stripeKey() {
    return this.publicKeyValue || document.querySelector('meta[name="stripe-key"]').getAttribute("content")
  }
}
